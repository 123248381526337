<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-splitter
          v-model="splitterModel"
          :limits="[20, 80]"
          unit="%"
        >
          <template v-slot:before>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-table
                ref="grpTable"
                title="Stepper 그룹"
                tableId="grpTable"
                :columnSetting="false"
                :isFullScreen="false"
                :usePaging="false"
                :filtering="false"
                :columns="grpGrid.columns"
                :data="grpGrid.data"
                selection="single"
                rowKey="stepperGrpCd"
                @rowClick="getMsts"
                :isExcelDown="false"
                :editable="editable"
              >
                <!-- 버튼 영역 -->
                <template slot="table-button">
                  <c-btn label="" icon="add" @btnClicked="addRowGrp" v-if="editable" />
                  <c-btn label="" icon="remove" @btnClicked="removeGrp" v-if="editable" />
                  <c-btn label="" icon="save" @btnClicked="saveGrp" v-if="editable" />
                </template>
              </c-table>
              <q-dialog v-model="prompt" persistent>
                <q-card style="min-width: 350px">
                  <q-card-section>
                    <div class="text-h6">Stepper 그룹코드</div>
                  </q-card-section>

                  <q-card-section class="q-pt-none">
                    <q-input dense v-model="hiddenStepperGrpCd" autofocus @keyup.enter="setGrp" />
                  </q-card-section>

                  <q-card-actions align="right" class="text-primary">
                    <q-btn flat label="취소" @click="cancelGrp" />
                    <q-btn flat label="추가" @click="setGrp" />
                  </q-card-actions>
                </q-card>
              </q-dialog>
            </div>
          </template>
          <template v-slot:after>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-table
                ref="mstTable"
                title="Stepper 마스터"
                tableId="mstTable"
                :columnSetting="false"
                :isFullScreen="false"
                :usePaging="false"
                :filtering="false"
                :columns="mstGrid.columns"
                :data="mstGrid.data"
                selection="multiple"
                rowKey="stepperMstCd"
                :isExcelDown="false"
                :editable="editable"
              >
                <!-- 버튼 영역 -->
                <template slot="table-button">
                  <c-btn label="추가" icon="add" @btnClicked="addRowMst" v-if="editable && isSelectGrp" />
                  <c-btn label="삭제" icon="remove" @btnClicked="removeMst" v-if="editable && isSelectGrp" />
                  <c-btn label="저장" icon="save" @btnClicked="saveMst" v-if="editable && isSelectGrp" />
                </template>
              </c-table>
              <q-dialog v-model="promptMst" persistent>
                <q-card style="min-width: 350px">
                  <q-card-section>
                    <div class="text-h6">코드</div>
                  </q-card-section>

                  <q-card-section class="q-pt-none">
                    <q-input dense v-model="hiddenStepperMstCd" autofocus @keyup.enter="setMst" />
                  </q-card-section>

                  <q-card-actions align="right" class="text-primary">
                    <q-btn flat label="취소" @click="cancelMst" />
                    <q-btn flat label="추가" @click="setMst" />
                  </q-card-actions>
                </q-card>
              </q-dialog>
            </div>
          </template>
        </q-splitter>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'stepper-manage',
  data() {
    return {
      splitterModel: 25,
      prompt: false,
      promptMst: false,
      searchParam: {
        useFlag: '',
      },
      editable: true,
      isSelectGrp: false,
      selectedGrpCd: '',
      grplistUrl: '',
      grpSaveUrl: '',
      grpDeleteUrl: '',
      mstlistUrl: '',
      mstSaveUrl: '',
      mstDeleteUrl: '',
      hiddenStepperGrpCd: '',
      hiddenStepperMstCd: '',
      grpGrid: {
        columns: [
          {
            name: 'stepperGrpCd',
            field: 'stepperGrpCd',
            label: '그룹코드',
            align: 'left',
            style: 'cursor:pointer;',
            sortable: false,
          },
          {
            name: 'stepperGrpNm',
            field: 'stepperGrpNm',
            label: '그룹명',
            align: 'left',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
      mstGrid: {
        columns: [
          {
            name: 'stepperMstCd',
            field: 'stepperMstCd',
            label: '코드',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'stepperMstNmKr',
            field: 'stepperMstNmKr',
            label: '타이틀',
            align: 'left',
            style: 'width:150px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'stepperMstNmEn',
            field: 'stepperMstNmEn',
            label: '타이틀-영어',
            align: 'left',
            style: 'width:150px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'stepperMstNmJa',
            field: 'stepperMstNmJa',
            label: '타이틀-일어',
            align: 'left',
            style: 'width:150px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '설명',
            align: 'left',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'iconClass',
            field: 'iconClass',
            label: '아이콘',
            align: 'center',
            style: 'width:90px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'colorClass',
            field: 'colorClass',
            label: '색상',
            align: 'center',
            style: 'width:90px',
            type: 'text',
            sortable: true,
          },
          {
            name: 'vuePath',
            field: 'vuePath',
            label: '뷰파일경로',
            align: 'left',
            style: 'width:200px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순서',
            style: 'width:40px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용',
            align: 'center',
            style: 'width:40px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
        ],
        data: [],
      },
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = (this.$store.getters.user.userId === 'U000000001' ? this.$route.meta.editable : false);
      this.grplistUrl = selectConfig.sys.stepper.grp.list.url;
      this.grpSaveUrl = transactionConfig.sys.stepper.grp.save.url;
      this.grpDeleteUrl = transactionConfig.sys.stepper.grp.delete.url;
      this.mstlistUrl = selectConfig.sys.stepper.mst.list.url;
      this.mstSaveUrl = transactionConfig.sys.stepper.mst.save.url;
      this.mstDeleteUrl = transactionConfig.sys.stepper.mst.delete.url;
      this.getGrps();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        for(let c = 0; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
    },
    getGrps() {
      this.$http.url = this.grplistUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grpGrid.data = _result.data;
      },);
    },
    getMsts(row) {
      if (row.editFlag != 'C') {
        this.isSelectGrp = true;
        this.selectedGrpCd = row.stepperGrpCd;
        this.$http.url = this.$format(this.mstlistUrl, this.selectedGrpCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.mstGrid.data = _result.data;
        },
        () => {
        });
      }
    },
    saveGrp() {
      let checkItem = ['stepperGrpCd', 'stepperGrpNm']
      let isConti = true;
      this.$_.forEach(this.grpGrid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [그룹코드, 그룹명]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grpGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.grpSaveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getGrps();
                this.rowRemoveSelect();
                this.isSelectGrp = false;
                this.mstGrid.data = [];
                window.getApp.$emit('ALERT', {
                  title: '안내 ', /* 안내 */
                  message: '저장되었습니다.', /* 저장되었습니다. */
                  type: 'success', // success / info / warning / error
                });
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    addRowGrp() {
      this.rowRemoveSelect();
      this.isSelectGrp = false;
      this.mstGrid.data = [];
      this.prompt = true;
    },
    cancelGrp() {
      this.hiddenStepperGrpCd = '';
      this.prompt = false;
    },
    setGrp() {
      this.prompt = false;
      this.grpGrid.data.splice(0, 0, {
        editFlag: 'C',
        stepperGrpCd: this.hiddenStepperGrpCd,
        stepperGrpNm: '',
      })
      this.hiddenStepperGrpCd = '';
    },
    removeGrp() {
      let selectData = this.$refs['grpTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.grpDeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getGrps();
              this.rowRemoveSelect();
              this.isSelectGrp = false;
              this.mstGrid.data = [];
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '삭제되었습니다.', /* 삭제되었습니다. */
                type: 'success', // success / info / warning / error
              });
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveMst() {
      let checkItem = ['stepperMstCd', 'stepperMstNmKr','sortOrder']
      let isConti = true;
      this.$_.forEach(this.mstGrid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [코드, 타이틀, 순번]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.mstGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.mstSaveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getMsts({stepperGrpCd: this.selectedGrpCd});
                window.getApp.$emit('ALERT', {
                  title: '안내 ', /* 안내 */
                  message: '저장되었습니다.', /* 저장되었습니다. */
                  type: 'success', // success / info / warning / error
                });
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    addRowMst() {
      this.promptMst = true;
    },
    cancelMst() {
      this.hiddenStepperMstCd = '';
      this.promptMst = false;
    },
    setMst() {
      this.promptMst = false;
      this.mstGrid.data.push({
        editFlag: 'C',
        stepperGrpCd: this.selectedGrpCd,
        stepperMstCd: this.hiddenStepperMstCd,
        stepperMstNm: '',
        remark: '',
        iconClass: '',
        colorClass: '',
        vuePath: '',
        sortOrder: '',
        useFlag: 'Y',
      })
      this.hiddenStepperMstCd = '';
    },
    removeMst() {
      let selectData = this.$refs['mstTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.mstDeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '삭제되었습니다.', /* 삭제되었습니다. */
                type: 'success', // success / info / warning / error
              });
              this.getMsts({stepperGrpCd: this.selectedGrpCd});
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
